<template>
  <div class="container p-l-40">
    <div class="p-t-20">
      <div class="soltWrap">
        <div class="search">
          <div class="searchInput el-input-group">
            <div class="el-input-group__prepend">起始日期</div>
            <el-date-picker
              v-model="dateArr"
              type="daterange"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="financial-info">
      <div class="fi-item flex p-t-b-8">
        <div class="fii-title">会员数量统计：</div>
        <div class="fii-info flex align-center">
          <span>开卡次数：{{ financeStatisticsData.vipUserNumber }} </span>
          <span>退卡次数：{{ financeStatisticsData.vipReturnNumber }}</span>
        </div>
      </div>
      <div class="fi-item flex p-t-b-8">
        <div class="fii-title">会员充值统计：</div>
        <div class="fii-info">
          <span>实际金额：{{ financeStatisticsData.vipRechargeMoney }} </span>
          <span>现金总额：{{ financeStatisticsData.vipRechargeCash }}</span>
          <span
            >支付宝总额：{{ financeStatisticsData.vipRechargeAlipay }}
          </span>
          <span>微信总额：{{ financeStatisticsData.vipRechargeWeixin }}</span>
          <span>银行卡总额：{{ financeStatisticsData.vipRechargeBank }}</span>
          <span>聚合支付金额：{{ financeStatisticsData.vipRechargeMix }}</span>
        </div>
      </div>
      <div class="fi-item flex p-t-b-8">
        <div class="fii-title">会员赠送统计：</div>
        <div class="fii-info flex align-center">
          <span
            >赠送金额：{{ financeStatisticsData.vipRechargeGiftMoney }}</span
          >
        </div>
      </div>
      <div class="fi-item flex p-t-b-8">
        <div class="fii-title">会员提现统计：</div>
        <div class="fii-info flex align-center">
          <span>退卡提现金额：{{ financeStatisticsData.vipReturnMoney }} </span>
        </div>
      </div>
      <div class="fi-item flex p-t-b-8">
        <div class="fii-title">会员消费统计：</div>
        <div class="fii-info flex align-center">
          <span>消费金额：{{ financeStatisticsData.consumeMoney }} </span>
        </div>
      </div>
      <div class="fi-item flex p-t-b-8">
        <div class="fii-title">综合统计：</div>
        <div class="fii-info flex align-center">
          <span>会员余额统计：{{ financeStatisticsData.vipUserMoney }}</span
          ><span style="border: none"
            >综合总收入：{{ financeStatisticsData.compositeTotal }}</span
          ><span style="color: rgb(139, 139, 139); border: none"
            >（会员充值总金额-会员退卡金额）</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  //引入组件
  components: {},
  data() {
    const self = this;
    return {
      number: "",
      loading: false,
      financeStatisticsData: {
        compositeTotal: "0.00",
        vipRechargeAlipay: "0.00",
        vipRechargeBank: "0.00",
        vipRechargeCash: "0.00",
        vipRechargeGiftMoney: "0.00",
        vipRechargeMix: "0.00",
        vipRechargeMoney: "0.00",
        vipRechargeNumber: 0,
        vipRechargeWeixin: "0.00",
        vipReturnMoney: "0.00",
        vipReturnNumber: 0,
        vipUserMoney: "0.00",
        vipUserNumber: 0,
      },
      dateArr: [
        self.$test.formatDate(new Date(), 1),
        self.$test.formatDate(new Date(), 1),
      ],
      searchQuery: { startDate: "", endDate: "" },
    };
  },
  ///////////////////////////////////////////////////////////////////////////////////////////
  //计算机属性
  ///////////////////////////////////////////////////////////////////////////////////////////
  computed: {},
  ///////////////////////////////////////////////////////////////////////////////////////////
  //监听页面加载，其参数为上个页面传递的数据，参数类型为Object（用于页面传参）
  ///////////////////////////////////////////////////////////////////////////////////////////
  created() {
    this.getFinanceStatistics();
  },
  watch: {
    dateArr(e) {
      this.searchQuery.startDate = e[0];
      this.searchQuery.endDate = e[1];
    },
    searchQuery: {
      handler() {
        this.getFinanceStatistics();
      },
      deep: true, //开启深度监听
    },
  },
  ///////////////////////////////////////////////////////////////////////////////////////////
  //方法调用
  ///////////////////////////////////////////////////////////////////////////////////////////
  methods: {
    // 获取设置详情
    async getFinanceStatistics() {
      const { searchQuery } = this;
      const data = {
        startDate: searchQuery.startDate,
        endDate: searchQuery.endDate,
      };
      console.log(data);
      let result = await api.common.get({
        apiName: "/vip/financeStatistics/financeStatistics",
        ...data,
      });
      this.financeStatisticsData = result.data;
    },
  },
};
</script>

<style scoped lang="scss">
.searchInput {
  width: 500px;
}
.financial-info {
  padding-top: 30px;
  margin: 0;
  font-size: 13px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #434343;
}

.financial-info .fi-item {
  padding: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.financial-info .fi-item .fii-title {
  width: 125px;
  color: #434343;
  font-size: 13px;
  background: #eee;
  border-radius: 19px;
  text-align: left;
  padding: 10px 0 10px 15px;
  margin-right: 20px;
}

.fii-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.fii-info span {
  padding-right: 40px;
  border-right: 1px solid #a0a0a0;
  margin-left: 20px;
  margin-right: 20px;
}

.fii-info span:last-child {
  border: none;
}
</style>
